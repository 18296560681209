body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  height: 100vh;
  background-color: black;
  // text highlighting disable
  // it messes with orbital controls
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .anchor {
    z-index: 10000;
    position: absolute !important;
    top: 10px;
  }

  &-menu-tip {
    color: white;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
